function App() {
    return (
        <div className="App bg-black h-screen w-screen flex items-center justify-start">
            <div className="text-white font-semibold font-raleway p-10 flex flex-col justify-start text-main-head-xs sm:text-main-head-sm md:text-main-head-md lg:text-main-head text-shadow-3d">
                <div>Coming</div>
                <div>Soon.</div>
            </div>
        </div>
    );
  }
  
  export default App;